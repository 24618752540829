<template>
  <div class="question">
    <div class="main-container">
      <section class="app-main">
        <div class="container" ref="container">
          <div class="main-background">
            <img
                style="width: 100%; height: 100%; object-fit: cover"
                :src="question['bg_img']"
            />
          </div>
          <div class="title">
            <span style="font-size: 1.8rem">{{ question["name"] || "-" }}</span>
            <span class="ct">答题倒计时：</span>
            <span>{{ timeLable }}</span>
          </div>

          <!--  -->
          <div class="main">
            <div class="sub-title">
              <span style="font-size: 1.5rem">{{
                  topic[activeKey]["name"]
                }}</span>
              <div class="progress">
                <i class="el-icon-time"></i>
                <span>当前进度:</span>
                <el-progress
                    :percentage="percentage_method"
                    :color="customColor"
                    style="width: 10rem"
                ></el-progress>
              </div>
            </div>
            <!-- 放大镜 -->
            <div
                class="zoom"
                v-for="(item,index) in topic[activeKey]['zoomIn']"
                :key="index"
                :style="{
                left: item['left'],
                top: item['top'],
              }"
            >
              <img :src="require('@/assets/img/zoomIn.png')" @click="showKeyWords(item['modalType'])" />
            </div>
            <div class="choose" v-if="topic[activeKey]['choose']!=undefined">
              <div class="choose-title">
                {{ topic[activeKey]["choose"]["title"] }}
              </div>
              <!-- 多选 -->
              <div
                  class="choose-option"
                  v-if="topic[activeKey]['choose']['type'] == 'check'"
              >
                <el-checkbox-group v-model="ckeckList">
                  <div
                      class="option"
                      v-for="(item, index) in topic[activeKey]['choose'][
                      'chooseOption'
                    ]"
                      :key="index"
                  >
                    <el-checkbox :label="item.value">{{
                        item.name
                      }}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <!-- 单选 -->
              <div
                  class="choose-option"
                  v-if="topic[activeKey]['choose']['type'] == 'radio'"
              >
                <el-radio-group v-model="radio">
                  <div
                      class="option"
                      v-for="(item, index) in topic[activeKey]['choose'][
                      'chooseOption'
                    ]"
                      :key="index"
                  >
                    <el-radio :label="item.value">{{ item.name }}</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>

          <!-- 按钮栏   -->
          <div class="toolbar">
            <div class="toolbar-content">
              <div class="btns">
                <el-button type="primary tips" v-if="type != 'opera'" @click="onTips">提示</el-button>

                <div class="right-btn">
                  <el-button type="primary" @click="onSubmit" :disabled = "subDisabled">提交</el-button>
                  <el-button type="primary" @click="reset">重做</el-button>
                  <el-button type="primary" @click="goBack">返回</el-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 弹出框   -->
          <broadcast
              ref ='broadcast'
              :red = 'red'
              :visible="keyVisible"
              :broadcastStatus="broadcastStatus"
              :keywordOption="topic[activeKey]['keywords']"
              :close="() => (keyVisible = false)"
          ></broadcast>


          <tips
              :visible="tipsVisible"
              :tips="topic[activeKey]['tips']"
              :close="() => (tipsVisible = false)"
          ></tips>
          <!-- -->

        </div>
      </section>
    </div>

    <!--音频   -->
    <audio v-show="false" ref="audio" controls src="" preload="metadata"  @play="handleMusicPlay" @ended="handleMusicEnded">
      <source src="" />
    </audio>
    <audio v-show="false" ref="audio2" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <!--    音频3 需要一直循环的声音-->
    <audio v-show="false" ref="audio3" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <Baywindow></Baywindow>
  </div>

</template>

<script>
import tips from "../../component/tips.vue";
import broadcast from "../../component/broadcast.vue";
import screenfull from "screenfull";
import Baywindow from "@/components/Baywindow.vue";

export default {
  data() {
    return {
      fullscreenFlag: false,
      question: null,
      // 进度条
      percentage: 20,
      customColor: "#409eff",
      customColors: [{ color: "#f56c6c", percentage: 20 }],
      keyVisible: false,
      tipsVisible:false,
      cities: [],
      topic: [],
      ckeckList: [],
      radio: null,
      activeKey: 0,
      // 答案集合
      ansObj:{},
      red: false,
      broadcastStatus:0,
      subDisabled:false,
      type:'viewr',
      timer:null,
      timeLable:'00:00',
      count:0,
      apiId:'10006',
      totalScore:0,
    };
  },
  components: {
    tips,
    broadcast,
    Baywindow
  },
  computed: {
    percentage_method() {
      return parseInt(this.percentage * (this.activeKey + 1));
    },
  },
  created() {
    this.type = this.$route.query.type
    this.count = this.$route.query.count
    let { question } = require(`@/api/wei${this.apiId}`)
    this.question = question
    this.topic = question["topic"]
    this.play(this.topic[0]['audio'])
    this.urls = question["tab"].filter((value,index,array)=>{
      if(this.topic[0]['tab'].indexOf(index)>=0){
        return array[index];
      }
    });
    this.broadcastStatus=Math.floor(Math.random() * 2)
    this.percentage = 100 / this.topic.length;

    // 全屏
    screenfull.toggle()
    if(this.type != 'viewr'){
      this.timeCountSetInterVal()
    }
  },
  mounted(){
    // 监听全屏变化
    screenfull.onchange(() => {
      this.isFullscreen = screenfull.isFullscreen
    })

  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    this.timer=null;
    next(true);
  },
  methods: {
    timeCountSetInterVal(){
      if(this.timer) return
      this.timer = setInterval(() => {
        this.count = this.count - 1
        this.fomatTime(this.count)
        if(this.count <= 0){
          clearInterval(this.timer)
          this.timer = null

          let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
          ids.push(this.apiId)
          sessionStorage.setItem('wids',JSON.stringify(ids))
          window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
          window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
          this.$router.replace("/result");
          this.reset();
          // this.restAll();
        }
      },1000)
    },
    fomatTime(totalSeconds){
      // ?️ 获取完整分钟数
      const minutes = Math.floor(totalSeconds / 60);
      // ?️ 获得剩余的秒数
      const seconds = totalSeconds % 60;
      // ✅ 格式化为 MM:SS
      if(totalSeconds<=0){
        this.timeLable = '00:00'
      }else{
        this.timeLable = `${this.padTo2Digits(minutes)}分:${this.padTo2Digits(seconds)}秒`;
      }
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    async play(val) {
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio.src = music;
        let audio = this.$refs.audio;
        audio.load();
        audio.play();
      });
    },
    play2(val) {
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio2.src = music;
        let audio = this.$refs.audio2;
        audio.load();
        audio.play();
      });
    },
    play3(val, loop = false, play = "play") {
      if (play === "stop") {
        this.$refs.audio3.pause();
      }
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio3.src = music;
        let audio = this.$refs.audio3;
        audio.loop = loop;
        audio.load();
        audio.play();

      });
    },
    handleMusicPlay(){
      // if(this.nextShow){
      //   this.subDisabled = true;
      // }
    },
    handleMusicEnded() {
      // this.buttondisable=false;
      // if(this.topic[this.activeKey]['branch'].length===1){
      //   this.subDisabled=false;
      // }
    },
    goBack() {
      this.$router.go(-1);
    },
    showKeyWords(type) {


      if(type == 'keyCode'){
        this.keyVisible = true;
        this.play2('zoomout.mp3')
      }

    },
    onTips(){
      this.tipsVisible = !this.tipsVisible;
      this.play2('zoomout.mp3')
    },
    onSubmit() {
      if (this.activeKey == this.topic.length) return;

      // 多选

      // 单选
      if(this.question.topic[this.activeKey]['type'] == 'radio'){
        if(!this.radio)
        {
          this.ansObj[this.activeKey] = false
        }else{
          let ans = this.question.topic[this.activeKey]['choose']['ans']
          this.ansObj[this.activeKey] = ans == this.radio
        }

      }
      let res = false;

      switch (this.activeKey){
        case 0:
          // console.log(this.radio)
          // console.log(this.broadcastStatus)
          if(this.radio==this.broadcastStatus){
            res = true
          }
          this.broadcastStatus = 0
          break;
        case 1:
          if(this.$refs.broadcast.red&&this.$refs.broadcast.seconds>=10){
            res = true
          }
          this.$refs.broadcast.submit()
          break;
        case 2:
          if(this.$refs.broadcast.light2=='green'){
            res = true
          }
          break;
      }
      this.ansObj[this.activeKey] = res;

      if(this.ansObj[this.activeKey]){
        this.totalScore+=parseFloat(this.topic[this.activeKey]['score'])
      }
      let sorceObj = sessionStorage.getItem("sorceObj")? JSON.parse(sessionStorage.getItem("sorceObj")):[];
      sorceObj[this.apiId] = this.totalScore
      sessionStorage.setItem("sorceObj",JSON.stringify(sorceObj));
      // console.log('分数',sorceObj)

      // if(this.question.topic[this.activeKey]['type'] == 'action'){
      //   this.play(this.topic[this.activeKey][['audio']])
      //   this.activeKey = this.activeKey + 1;
      // }
      this.play2('select.mp3');
      if(this.activeKey < (this.question.topic.length - 1)){

        this.activeKey = this.activeKey + 1;
        this.play(this.topic[this.activeKey][['audio']])
        //this.question['bg_img'] = url.src


      }else{
        window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
        window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));

        let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
        ids.push(this.apiId)
        sessionStorage.setItem('wids',JSON.stringify(ids))
        setTimeout(() => {
          this.$router.replace("/result");
        }, 500);
        return true;
      }
      this.reset()


    },
    reset(){
      this.keyVisible = false
      this.ckeckList = []
      this.radio = null
      this.red = false
      this.$refs.broadcast.isShow = false
      this.$refs.broadcast.doAudio = false
      this.$refs.broadcast.light = 'grey'
      this.$refs.broadcast.light1 = 'grey'
      this.$refs.broadcast.light2 = 'grey'
      this.$refs.broadcast.url = 'url("https://bankaoedu.com/files/xc/closed.png") no-repeat'
      this.$refs.broadcast.blue = false
    },

    //
    // // 全面屏
    fullscreen() {
      // 需要全屏显示的dom元素
      let dom = this.$el.querySelector(".container");
      // 调用全屏方法
      this.$fullscreen.enter(dom, {
        wrap: false,
        callback: (f) => {
          this.fullscreenFlag = f;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container .toolbar {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 2.5rem;
  z-index: 9999;
}

.question {
  position: relative;
  height: 100%;
  width: 100%;
  // ======
}
// .main-container {
//   width: 100%;
//   height: 100%;
//   transition: margin-left 0.28s;
//   margin-left: 0;
//   position: relative;
//   background: #f8f8f8;
// }
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 0;
  position: relative;
  background: #f8f8f8;
}
.app-main {
  position: relative;
}

.container {
  // 全屏下
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  // 非全屏下
  //   width: 120rem;
  // height: 67.5rem;
  // margin: 0 auto;
  // position: relative;


  .main-background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
  }
  .title {
    height: 2.5rem;
    background-color: #409eff;
    line-height: 2.5rem;
    color: #fff;
    font-weight: 500;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 2rem;
    .ct {
      margin-left: auto;
    }
  }
  .main {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    .zoom {
      position: absolute;
      cursor: pointer;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .sub-title {
      height: 2.5rem;
      background-color: #fff;
      padding: 0 1.25rem;
      position: absolute;
      z-index: 8;
      left: 0;
      top: 2.5rem;
      width: 100%;
      font-size: 1.5rem;
      .progress {
        font-size: 0.875rem;
        span {
          font-size: 0.8125rem;
          margin: 0 0.3125rem;
        }
      }
    }
    // 选这题
    .choose {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .choose-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  //   按钮
  .toolba {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 2.5rem;
    z-index: 9999;
  }
  .toolbar-content {
    margin: 0 12.5rem;
    .right-btn {
      margin-left: auto;
    }
    button {
      padding: 0.625rem 1.875rem;
      color: #fff;
      font-size: 16px;
      margin: 0 1.875rem;
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }
  .toolbar-content .btns,
  .toolbar-content .right-btn {
    display: flex;
    align-items: center;
  }
  .toolbar-content .btns {
    position: absolute;
    z-index: 9999;
    left: 6.25rem;
    right: 6.25rem;
    bottom: 0;
  }
}

.container .main .sub-title .progress,
.container .main .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// 屏幕
</style>
