var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',{staticClass:"main-container"},[_c('section',{staticClass:"app-main"},[_c('div',{ref:"container",staticClass:"container"},[_c('div',{staticClass:"main-background"},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.question['bg_img']}})]),_c('div',{staticClass:"title"},[_c('span',{staticStyle:{"font-size":"1.8rem"}},[_vm._v(_vm._s(_vm.question["name"] || "-"))]),_c('span',{staticClass:"ct"},[_vm._v("答题倒计时：")]),_c('span',[_vm._v(_vm._s(_vm.timeLable))])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"sub-title"},[_c('span',{staticStyle:{"font-size":"1.5rem"}},[_vm._v(_vm._s(_vm.topic[_vm.activeKey]["name"]))]),_c('div',{staticClass:"progress"},[_c('i',{staticClass:"el-icon-time"}),_c('span',[_vm._v("当前进度:")]),_c('el-progress',{staticStyle:{"width":"10rem"},attrs:{"percentage":_vm.percentage_method,"color":_vm.customColor}})],1)]),_vm._l((_vm.topic[_vm.activeKey]['zoomIn']),function(item,index){return _c('div',{key:index,staticClass:"zoom",style:({
              left: item['left'],
              top: item['top'],
            })},[_c('img',{attrs:{"src":require('@/assets/img/zoomIn.png')},on:{"click":function($event){return _vm.showKeyWords(item['modalType'])}}})])}),(_vm.topic[_vm.activeKey]['choose']!=undefined)?_c('div',{staticClass:"choose"},[_c('div',{staticClass:"choose-title"},[_vm._v(" "+_vm._s(_vm.topic[_vm.activeKey]["choose"]["title"])+" ")]),(_vm.topic[_vm.activeKey]['choose']['type'] == 'check')?_c('div',{staticClass:"choose-option"},[_c('el-checkbox-group',{model:{value:(_vm.ckeckList),callback:function ($$v) {_vm.ckeckList=$$v},expression:"ckeckList"}},_vm._l((_vm.topic[_vm.activeKey]['choose'][
                    'chooseOption'
                  ]),function(item,index){return _c('div',{key:index,staticClass:"option"},[_c('el-checkbox',{attrs:{"label":item.value}},[_vm._v(_vm._s(item.name))])],1)}),0)],1):_vm._e(),(_vm.topic[_vm.activeKey]['choose']['type'] == 'radio')?_c('div',{staticClass:"choose-option"},[_c('el-radio-group',{model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},_vm._l((_vm.topic[_vm.activeKey]['choose'][
                    'chooseOption'
                  ]),function(item,index){return _c('div',{key:index,staticClass:"option"},[_c('el-radio',{attrs:{"label":item.value}},[_vm._v(_vm._s(item.name))])],1)}),0)],1):_vm._e()]):_vm._e()],2),_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"toolbar-content"},[_c('div',{staticClass:"btns"},[(_vm.type != 'opera')?_c('el-button',{attrs:{"type":"primary tips"},on:{"click":_vm.onTips}},[_vm._v("提示")]):_vm._e(),_c('div',{staticClass:"right-btn"},[_c('el-button',{attrs:{"type":"primary","disabled":_vm.subDisabled},on:{"click":_vm.onSubmit}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.reset}},[_vm._v("重做")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goBack}},[_vm._v("返回")])],1)],1)])]),_c('broadcast',{ref:"broadcast",attrs:{"red":_vm.red,"visible":_vm.keyVisible,"broadcastStatus":_vm.broadcastStatus,"keywordOption":_vm.topic[_vm.activeKey]['keywords'],"close":() => (_vm.keyVisible = false)}}),_c('tips',{attrs:{"visible":_vm.tipsVisible,"tips":_vm.topic[_vm.activeKey]['tips'],"close":() => (_vm.tipsVisible = false)}})],1)])]),_c('audio',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"audio",attrs:{"controls":"","src":"","preload":"metadata"},on:{"play":_vm.handleMusicPlay,"ended":_vm.handleMusicEnded}},[_c('source',{attrs:{"src":""}})]),_c('audio',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"audio2",attrs:{"controls":"","src":"","preload":"metadata"}},[_c('source',{attrs:{"src":""}})]),_c('audio',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"audio3",attrs:{"controls":"","src":"","preload":"metadata"}},[_c('source',{attrs:{"src":""}})]),_c('Baywindow')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }